<script setup lang="ts">
import { useAssistantSettingsStore } from "@/stores/assistantSettingsStore";

const emit = defineEmits(["close"]);

const { assistantSettings } = useAssistantSettingsStore();

const { logout } = useSanctumAuth();

const close = () => {
  // window.setTimeout(() => {
  emit("close");
  // }, 300);
};
</script>

<template>
  <div class="menu">
    <BaseIcon name="close" class="close" @click="close" />
    <nav class="nav" @click="close">
      <MainMenuItem @click="navigateTo('/')">Home</MainMenuItem>
      <MainMenuItem @click="navigateTo('/support')">Support</MainMenuItem>
      <MainMenuItem @click="navigateTo('/privacy')">Privacy</MainMenuItem>
      <MainMenuItem
        v-if="!assistantSettings.auth.has_auto_login"
        @click="logout"
        >Uitloggen</MainMenuItem
      >
    </nav>
    <PoweredBy
      v-if="assistantSettings.config.has_branding"
      class="powered-by"
    ></PoweredBy>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  background: var(--menu-body-background-color);
  inset: 0;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: var(--menu-body-text-color);

  a {
    color: var(--menu-body-text-color);
  }
}

.nav {
  flex-grow: 1;
  padding: 20px 0;
  overflow: auto;
}

.close {
  align-self: flex-end;
  margin: 20px 20px 0 20px;
  font-size: 2.5rem;
  cursor: pointer;
}

.powered-by {
  margin: 30px;
  align-self: center;
}
</style>
