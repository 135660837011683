<script setup lang="ts">
import { useAssistantSettingsStore } from "@/stores/assistantSettingsStore";
import { lightOrDark } from "@/utils";

const { assistantSettings } = useAssistantSettingsStore();

const menuOpen = ref(false);
const bus = useEventBus<string>("main");

const openMenu = () => {
  bus.emit("overlay:open");
  menuOpen.value = true;
};

const closeMenu = () => {
  bus.emit("overlay:close");
  menuOpen.value = false;
};

const logoUrl = computed(() => {
  return lightOrDark(assistantSettings.theme.main_body_background_color) ===
    "dark"
    ? assistantSettings.images.logo_light
    : assistantSettings.images.logo_dark;
});
</script>

<template>
  <header class="main-header">
    <div id="header" class="section is-left"></div>
    <div class="section is-middle">
      <div class="logo" @click="navigateTo('/')">
        <!-- <img src="~/assets/images/logo-main.png" /> -->
        <img :src="logoUrl" />
      </div>
    </div>
    <div class="section is-right">
      <div class="button is-menu">
        <BaseIcon name="menu" class="button-icon" @click="openMenu" />
      </div>
    </div>
    <Teleport to="body">
      <TransitionScale :scale="1.2" :duration="300">
        <MainMenu v-if="menuOpen" @close="closeMenu"></MainMenu>
      </TransitionScale>
    </Teleport>
  </header>
</template>

<style lang="scss" scoped>
.main-header {
  position: relative;
  top: 0;
  display: flex;
  height: 80px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  background-color: var(--main-body-background-color);
  backdrop-filter: blur(5px);
  padding-top: env(safe-area-inset-top);
  // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.section {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  &.is-left {
    justify-content: flex-start;
    margin-right: auto;
    padding: 20px;
  }

  &.is-right {
    justify-content: flex-end;
    margin-left: auto;
    padding: 20px;
  }
}

.logo {
  cursor: pointer;
  img {
    max-width: 180px;
    max-height: 40px;
  }
}

.button {
  cursor: pointer;
}

.button-icon {
  font-size: 2.5rem;
}
</style>
