<script setup lang="ts">
const {
  duration = 300,
  transition = "ease",
  scale = 0,
} = defineProps<{
  duration?: number;
  transition?: string;
  scale?: number;
}>();

const beforeEnter = (el: HTMLElement) => {
  el.style.scale = scale.toString();
  el.style.opacity = "0";
  el.style.transition = `scale ${duration}ms ${transition}, opacity ${duration}ms ${transition}`;
};

const enter = (el: HTMLElement) => {
  requestAnimationFrame(() => {
    el.style.scale = "1";
    el.style.opacity = "1";
  });
};

const leave = (el: HTMLElement) => {
  el.style.scale = scale.toString();
  el.style.opacity = "0";
};
</script>

<template>
  <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <slot></slot>
  </transition>
</template>

<style lang="scss"></style>
